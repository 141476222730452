import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../mainPages/NotFound';
import { useSelector, useDispatch } from 'react-redux';
import { setName } from '../../redux/components/firstSlice';
import BuyBlock from '../../components/BuyBlock';
import { yesNo } from '../../components/ExportFunc';

const AboutProjector = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name, dataM2, lang, dataItemsM } = useSelector((state) => state.filters);

  const [imgStatte, setImgState] = React.useState(0);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serieName = params.get('name');

    if (serieName !== null && serieName !== '' && dataItemsM) {
      const filteredData = dataItemsM.filter((obj) => obj.base.code === serieName);
      /*   localStorage.setItem('name', JSON.stringify(serieName)); */
      if (filteredData.length > 0) {
        dispatch(setName(filteredData[0]));
      }
    }
    Cookies.set('key', 'value', { sameSite: 'None', secure: true });
  }, [location.search, dataItemsM, dispatch]);

  const displayData = name;

  if (!displayData) {
    return <NotFound />;
  }

  return (
    <div className='about-laptop'>
      <div className='left-block'>
        <div className='images'>
          <h2 className='yepo-top'>
            <p className='yepo'>YEPO {displayData.base.seria}</p>
            {displayData.base.drivers && (
              <Link
                className='drivers'
                to={displayData.base.drivers}>
                {dataM2.BuyBlock.Download[lang]}
              </Link>
            )}
          </h2>
          <div>
            <div className='images-block'>
              {/* {name.images && name.images.map((image, index) => (
                                <img key={index} src={`public/laptopsImages/i10/${image}.jpg`} alt={`Image ${index}`} />
                            ))} */}
              {/*   {name.images && name.images.map((image, index) => (
                                <img key={index} src={`${process.env.PUBLIC_URL}/laptopsImages/i10/${image}.jpg`} alt={`Image ${index}`} />
                            ))} */}
              {displayData.base.images &&
                displayData.base.images.map((obj, i) => {
                  return (
                    <button
                      className={i === imgStatte ? 'active' : ''}
                      key={i}
                      onClick={() => setImgState(i)}>
                      <img
                        src={
                          '../../itemsImages/' +
                          displayData.base.code +
                          '/' +
                          displayData.base.images[i] +
                          '.jpg'
                        }
                        alt='mainimage'
                      />
                    </button>
                  );
                })}
            </div>
            <div className='images-block-main'>
              {/*  <img src={`${process.env.PUBLIC_URL}/images/${name.seria}/${name.images[imgStatte]}.jpg`} alt="mainimage" /> */}
              <img
                src={`${process.env.PUBLIC_URL}/itemsImages/${displayData.base.code}/${displayData.base.images[imgStatte]}.jpg`}
                alt='mainimage'
              />
              {/*  <img src={"../../laptopsImages/" + name.seria + "/" + name.images[imgStatte] + ".jpg"} alt="mainimage" /> */}
            </div>
          </div>
        </div>
        <div className='feature'>
          <h2>{dataM2.SectionBase[lang]}</h2>
          <div className='l-main-block'>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionProjector.Section1.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionProjector.Section1.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.DeviceClass[lang]}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section1.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Technology}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section1.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.Application[lang]}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section1.SS5[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.RealResolution}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section1.SS6[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.Widescreen, lang)}</p>
                </div>
                <div> 
                  <p>{dataM2.SectionProjector.Section1.SS7[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.S3DSupport, lang)}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionProjector.Section2.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionProjector.Section2.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.LampType, lang)}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section2.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.LampLife}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section2.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Contrast}</p>
                </div>
                <div>
                  <p>{dataM2.SectionProjector.Section2.SS5[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Light}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionProjector.Section3.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionProjector.Section3.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.ProjectionDistance}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionProjector.Section4.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionProjector.Section4.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Communications}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuyBlock />
    </div>
  );
};

export default AboutProjector;
