import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../mainPages/NotFound';
import { useSelector, useDispatch } from 'react-redux';
import { setName } from '../../redux/components/firstSlice';
import BuyBlock from '../../components/BuyBlock/index';
import { yesNo } from '../../components/ExportFunc';

const AboutLaptop = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { name, dataM2, dataItemsM, lang } = useSelector((state) => state.filters);

  const [imgStatte, setImgState] = React.useState(0);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serieName = params.get('name');

    if (serieName !== null && serieName !== '' && dataItemsM) {
      const filteredData = dataItemsM.filter((obj) => obj.base.code === serieName);
      /* localStorage.setItem('name', JSON.stringify(serieName)); */
      if (filteredData.length > 0) {
        dispatch(setName(filteredData[0]));
      }
    }
    Cookies.set('key', 'value', { sameSite: 'None', secure: true });
  }, [location.search, dataItemsM, dispatch]);
  const displayData = name;

  if (!displayData || Object.keys(displayData).length === 0) {
    return <NotFound />;
  }

  return (
    <div className='about-laptop'>
      <div className='left-block'>
        <div className='images'>
          <h2 className='yepo-top'>
            <p className='yepo'>YEPO {displayData.base.seria}</p>
            {displayData.base.drivers && (
              <Link
                className='drivers'
                to={displayData.base.drivers}>
                {dataM2.BuyBlock.Download[lang]}
              </Link>
            )}
          </h2>
          <div>
            <div className='images-block'>
              {/* {displayData.images && displayData.images.map((image, index) => (
                                <img key={index} src={`public/laptopsImages/i10/${image}.jpg`} alt={`Image ${index}`} />
                            ))} */}
              {/*   {displayData.images && displayData.images.map((image, index) => (
                                <img key={index} src={`${process.env.PUBLIC_URL}/laptopsImages/i10/${image}.jpg`} alt={`Image ${index}`} />
                            ))} */}
              {displayData.base.images &&
                displayData.base.images.map((obj, i) => {
                  return (
                    <button
                      className={i === imgStatte ? 'active' : ''}
                      key={i}
                      onClick={() => setImgState(i)}>
                      <img
                        src={
                          '../../itemsImages/' +
                          displayData.base.code +
                          '/' +
                          displayData.base.images[i] +
                          '.jpg'
                        }
                        alt='mainimage'
                      />
                    </button>
                  );
                })}
            </div>
            <div className='images-block-main'>
              {/*  <img src={`${process.env.PUBLIC_URL}/images/${displayData.seria}/${displayData.images[imgStatte]}.jpg`} alt="mainimage" /> */}
              <img
                src={`${process.env.PUBLIC_URL}/itemsImages/${displayData.base.code}/${displayData.base.images[imgStatte]}.jpg`}
                alt='mainimage'
              />
              {/*  <img src={"../../laptopsImages/" + displayData.base.seria + "/" + displayData.base.images[imgStatte] + ".jpg"} alt="mainimage" /> */}
            </div>
          </div>
        </div>
        <div className='feature'>
          <h2>{dataM2.SectionBase[lang]}</h2>
          <div className='l-main-block'>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section1.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section1.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.webcamera, lang)}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section1.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.privod[lang]}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section1.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.podsvetkaklavi, lang)}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section1.SS5[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.year}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section1.SS6[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.country[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section2.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section2.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.OS}</p>
                </div>
                {/* <div>
                  <p>{dataM2.SectionLaptop.Section2.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight warning'>{displayData.needLang.warning[lang]}</p>
                </div> */}
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section3.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section3.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.Bluetooth[lang]}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section3.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.wifi[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section4.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section4.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.storage}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section4.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.typeOfStorage}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section4.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.memory}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section5.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section5.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.VideoCart}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section5.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.videoMemory}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section5.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.TypeOfVideoCart[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section6.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section6.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Size}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section6.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.NetWeight}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section6.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.material[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section7.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section7.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.seria}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section7.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.class[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section8.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.processor}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.Yadra}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{yesNo(displayData.base.integr, lang)}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS5[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.BaseChastotaProc}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS6[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.MaxChastotaProc}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section8.SS7[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.pokolenie[lang]}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section9.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section9.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.razreshenie}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section9.SS3[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.LCD}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section9.SS4[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.PokytieEkrana[lang]}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section9.SS5[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.ChastotaObnovleniya}</p>
                </div>
                <div>
                  <p>{dataM2.SectionLaptop.Section9.SS6[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.base.TypeOfMatric}</p>
                </div>
              </div>
            </div>
            <div className='l-main-block2 '>
              <div className='l-left-block'>{dataM2.SectionLaptop.Section10.SS1[lang]}</div>
              <div className='l-right-block'>
                <div>
                  <p>{dataM2.SectionLaptop.Section10.SS2[lang]}</p>
                  <p className='l-center'></p>
                  <p className='fight'>{displayData.needLang.port[lang]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuyBlock />
    </div>
  );
};

export default AboutLaptop;
