import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

export const Admin = () => {
  const { lang } = useSelector((state) => state.filters);
  const isSearch = React.useRef(false); // ----- Есть ли в строке поиска параметры фильтрации, изначально ставим что их нет
  const [items, setItems] = React.useState([]); /* объекты из бэкэнда */
  const langType = lang[('kz', 'ru', 'eng')];

  React.useEffect(() => {
    if (!isSearch.current) {
      axios.get(`http://localhost:4000/${langType}`).then((response) => {
        setItems(response.data);
        console.log('Первый запрос пошел');
      });
    }
  }, [langType]);

  const categories = items.map((cat, i) => <p>cat</p>);
  return (
    <>
      <p>{categories}</p>
    </>
  );
};
