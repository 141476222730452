export const yesNo = (obj, lang) => {
    const JSobj = typeof obj === 'string' ? parseInt(obj, 10) : obj;
    if (JSobj === 1) {
        switch (lang) {
            case 'ru':
                return 'Да';
            case 'kz':
                return 'Иә';
            default:
                return obj;
        }
    } else if (JSobj === 0) {
        switch (lang) {
            case 'ru':
                return 'Нет';
            case 'kz':
                return 'Жоқ';
            default:
                return obj;
        }
    }
    return obj;
};
