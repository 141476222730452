import React from 'react';
import { useSelector } from 'react-redux';

export const MainPage = () => {
  const { dataP, lang } = useSelector((state) => state.filters);

  const iphone12 = window.innerWidth <= 430;
  const iphone14proMax = window.innerWidth <= 800;

  return (
    <>
      <section className='mainPage'>
        <img
          src={
            iphone14proMax
              ? '../../../itemsImages/mainPage/main-img-mob.jpg'
              : '../../../itemsImages/mainPage/main-img-comp.jpg'
          }
          alt='img'
        />

        <iframe
          className='mainVideo'
          src='https://www.youtube.com/embed/wmlVYIUvYps?si=FPgpnCTpDkxKwKcK'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
          style={{ border: 'none' }}></iframe>

        {dataP.SecondBlock.map((obj, i) => (
          <div
            className='rest-blocks'
            key={i}>
            {iphone12 ? (
              <div className='rest-block'>
                <div className='second-block'>
                  <h1 className='title'>{obj.title[lang]}</h1>
                  <p className='description'>{obj.text[lang]}</p>
                </div>
                <div className='first-block'>
                  <img
                    className='block-img'
                    src={obj.image}
                    alt={obj.title[lang]}
                  />
                </div>
              </div>
            ) : i % 2 === 0 ? (
              <div className='rest-block'>
                <div className='first-block'>
                  <img
                    className='block-img'
                    src={obj.image}
                    alt={obj.title[lang]}
                  />
                </div>
                <div className='second-block'>
                  <h1 className='title'>{obj.title[lang]}</h1>
                  <p className='description'>{obj.text[lang]}</p>
                </div>
              </div>
            ) : (
              <div className='rest-block'>
                <div className='second-block'>
                  <h1 className='title'>{obj.title[lang]}</h1>
                  <p className='description'>{obj.text[lang]}</p>
                </div>
                <div className='first-block'>
                  <img
                    className='block-img'
                    src={obj.image}
                    alt={obj.title[lang]}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </section>
    </>
  );
};
