import { createSlice } from '@reduxjs/toolkit';
/* ---------Данные---------- */
/* import dataMain from "../../assets/dataMain.json"; */
/* import dataItems from "../../assets/dataItems.json"; */
import dataItems2 from "../../assets/dataItems3.json";
import dataMainPage from "../../assets/mainPage.json";

import dataMain2 from "../../assets/dataMain2.json";





const initialState = {
    lang: 'ru',
    // Стейт, хранящий в себе название конкретной товарной группы
    name: null,
    // Стейт, хранящий в себе активную страницу
    activePage: 0,
    // Изначально загружаем данные на русском языке
    dataItemsM: dataItems2,
    /* dataL: dataItems['ru'], */
    dataP: dataMainPage,
    dataM2: dataMain2
}

export const firstSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload;/* 
            state.dataL = dataItems[state.lang]; */
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setActivePage: (state, action) => {
            state.activePage = action.payload;
            state.name = null; // Clear name when changing the page
        },
    },
});

// Action creators are generated for each case reducer function
export const { setLang, setName, setActivePage } = firstSlice.actions;

export default firstSlice.reducer;
