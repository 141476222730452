import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ItemBlock from '../../components/ItemBlock';
import { setActivePage } from '../../redux/components/firstSlice';

const Products = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { activePage, dataItemsM } = useSelector((state) => state.filters);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typeOfProduct = params.get('name');
    if (typeOfProduct) {
      dispatch(setActivePage(typeOfProduct));
    }
  }, [location.search, dispatch]);

  const filteredItems = dataItemsM.filter((obj) => obj.base.typeOfProduct === activePage);

  return (
    <>
      {filteredItems.map((obj, i) => (
        <ItemBlock
          key={i}
          obj={obj}
          index={i}
        />
      ))}
    </>
  );
};

export default Products;
