import React from 'react';
import { Link } from 'react-router-dom';
import { setActivePage } from '../../redux/components/firstSlice';
import { useDispatch } from 'react-redux';

function SvgComponent() {
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(setActivePage(0));
    window.scrollTo(0, 0);
  };

  return (
    <Link
      className='logo'
      to={'/'}
      onClick={() => toggle()}>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 984.000000 203.000000'
        preserveAspectRatio='xMidYMid meet'>
        <g
          className='svg-g'
          transform='translate(0.000000,203.000000) scale(0.100000,-0.100000)'
          stroke='none'>
          <path
            id='svg-g'
            d='M90 1985 c0 -5 127 -157 450 -539 74 -88 204 -241 287 -340 l153 -181 0 -405 c0 -223 4 -411 8 -418 7 -9 66 -12 263 -10 l254 3 3 410 2 411 128 150 c70 83 215 253 322 379 367 430 450 529 450 537 0 4 -137 8 -304 8 l-304 0 -272 -340 c-150 -187 -276 -337 -281 -334 -8 5 -104 122 -413 503 l-138 171 -304 0 c-167 0 -304 -2 -304 -5z'
          />
          <path
            id='svg-g'
            d='M2937 1974 c-274 -49 -471 -216 -558 -470 -49 -146 -63 -246 -63 -464 -1 -214 12 -313 60 -455 90 -270 293 -437 584 -480 81 -13 230 -15 856 -13 l759 3 3 192 2 192 -742 3 -743 3 -57 23 c-62 25 -123 84 -157 151 -24 47 -54 167 -46 181 4 6 331 10 871 10 l864 0 0 195 0 195 -47 0 c-27 0 -410 0 -853 1 -443 0 -812 4 -819 9 -18 10 -10 66 19 145 29 75 105 154 175 181 47 18 88 19 793 22 l742 3 0 194 0 195 -782 -1 c-626 -1 -799 -4 -861 -15z'
          />
          <path
            id='svg-g'
            d='M4945 1978 c-3 -7 -4 -96 -2 -198 l2 -185 760 -5 c512 -4 770 -9 791 -17 125 -45 183 -129 171 -250 -10 -101 -65 -162 -178 -199 -50 -17 -116 -18 -801 -22 l-747 -3 2 -502 2 -502 260 0 260 0 3 307 2 306 513 5 c560 5 592 8 772 68 227 76 365 205 421 394 27 91 25 269 -3 360 -78 249 -322 403 -701 444 -154 17 -1521 15 -1527 -1z'
          />
          <path
            id='svg-g'
            d='M7883 1975 c-119 -32 -232 -114 -300 -216 -26 -39 -43 -75 -40 -83 5 -14 97 -16 754 -18 l749 -3 58 -35 c65 -39 106 -85 139 -157 22 -47 22 -56 22 -423 l0 -375 -27 -57 c-31 -66 -91 -128 -156 -160 -47 -23 -49 -23 -447 -23 -398 0 -400 0 -443 23 -66 35 -119 88 -149 150 -27 55 -28 64 -33 247 l-5 190 -255 0 -255 0 1 -195 c0 -186 1 -199 27 -273 65 -188 187 -327 360 -411 133 -65 166 -68 788 -64 621 4 588 1 744 81 106 55 234 181 288 284 71 137 77 179 77 583 0 322 -2 364 -20 434 -49 192 -173 344 -360 440 -148 76 -146 76 -847 75 -500 0 -628 -3 -670 -14z'
          />
        </g>
      </svg>
    </Link>
  );
}

export default SvgComponent;
